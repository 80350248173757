<template>
  <v-sheet class="pa-3">
    <v-overlay :value="loadingUploadSuccess" :opacity="0.7" z-index="1500">
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        :value="loadingProgress"
        color="primary"
      >
        {{ loadingProgress }} %
      </v-progress-circular>
    </v-overlay>

    <v-btn text @click="goBack()">
      <v-icon class="mr-2" medium>mdi-arrow-left</v-icon>
      {{ $t("app.back") }}
    </v-btn>

    <v-card flat>
      <v-card-title>
        <h4>{{ $t("master_data.student.upload_excel.add_format_excel") }}</h4>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row justify="center" align="center">
          <v-col cols="12" sm="5">
            <UploadExcel
              :label="$t('master_data.student.upload_excel.upload_excel_file')"
              multipleSheet
              :clearData="clearData"
              :onSuccess="uploadExcelHandler"
              @handleClear="handleClearExcel"
            >
            </UploadExcel>
          </v-col>
          <v-col cols="12" sm="5">
            <v-file-input
              v-model="filePhoto"
              :label="$t('master_data.student.upload_excel.upload_image')"
              multiple
              outlined
              dense
              hide-details
              accept="image/*"
              @change="handleUploadFoto"
            >
              <template slot="append-outer">
                <v-menu open-on-hover left top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                      mdi-alert-circle-outline
                    </v-icon>
                  </template>
                  <v-card flat class="pa-2 elevation-0" width="450">
                    <v-img
                      src="https://sistesi.s3-ap-southeast-1.amazonaws.com/petunjuk/upload-excel-siswa.svg"
                    />
                  </v-card>
                </v-menu>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="12" sm="2">
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="dataExcel.length == 0 || errorData"
                color="primary"
                depressed
                block
                @click="submitExcel"
              >
                {{ $t("app.save") }}
              </v-btn>
            </v-row>
          </v-col>
          <v-col v-if="imageFileTemp.length > 0" cols="12" class="mt-2">
            <v-card outlined class="pa-2">
              <v-row no-gutters align="center">
                <v-chip
                  v-for="img in imageFileTemp.slice(0, 5)"
                  :key="img.name"
                  class="mb-1 mr-1"
                  close
                  @click:close="handleDeleteFile(img)"
                  >{{ img.name }}</v-chip
                >
              </v-row>
            </v-card>
            <v-row no-gutters justify="end" class="mt-2">
              <v-btn
                text
                color="error"
                class="caption mr-2"
                small
                @click="handleDeleteFile(null)"
                >{{ $t("app.delete") }}</v-btn
              >
              <v-btn
                v-if="imageFileTemp.length > 5"
                text
                outlined
                class="caption"
                small
                @click="viewImageModal = true"
                >{{ $t("app.view_more") }}</v-btn
              >
            </v-row>
            <v-dialog v-model="viewImageModal">
              <v-card flat>
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-btn small icon @click="viewImageModal = false"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </v-card-title>
                <v-card-text class="mt-2">
                  <v-data-table
                    :headers="[
                      {
                        text: $t('master_data.student.upload_excel.filename'),
                        value: 'name'
                      }
                    ]"
                    :items="imageFileTemp"
                    :items-per-page="10"
                    dense
                  >
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>

        <v-alert
          v-if="errorMaxData"
          color="warning"
          dense
          class="white--text mt-4"
        >
          {{ $t("master_data.student.upload_excel.error_max_data") }}
        </v-alert>

        <v-card v-if="errorData" class="pa-5 mt-6" outlined>
          <v-alert dense type="error">
            {{ $t("master_data.student.upload_excel.error_mandatory") }}
          </v-alert>
          <v-row justify="center">
            <v-col>
              <v-data-table
                :headers="[
                  {
                    text: $t('master_data.student.upload_excel.row'),
                    value: 'row'
                  },
                  {
                    text: $t('master_data.student.upload_excel.column'),
                    value: 'column'
                  }
                ]"
                :items="dataError"
                :items-per-page="5"
                class="elevation-0"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>

        <v-card v-if="errorResponse == true" class="pa-5 mt-6" outlined>
          <v-alert dense type="error">
            {{ $t("master_data.student.upload_excel.error_response") }}
          </v-alert>
          <v-row justify="center">
            <v-col>
              <v-data-table
                :headers="[
                  {
                    text: $t('master_data.student.upload_excel.row'),
                    value: 'row'
                  },
                  {
                    text: $t('master_data.student.upload_excel.name'),
                    value: 'name',
                    width: 150
                  },
                  {
                    text: $t('master_data.student.upload_excel.pob'),
                    value: 'pob',
                    width: 100
                  },
                  {
                    text: $t('master_data.student.upload_excel.dob'),
                    value: 'dob',
                    width: 130
                  },
                  {
                    text: $t('master_data.student.upload_excel.msg'),
                    value: 'message'
                  }
                ]"
                :items="errorResponseList"
                :items-per-page="10"
                sort-by="row"
                class="elevation-0"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
import UploadExcel from "@/components/UploadExcel";
import { deleteFile, uploadFile } from "@/api/admin/admin";
import { insert_ppdb_siswa } from "@/api/homepage/ppdb";
import { sliceAwsPath } from "@/utils/uploadS3";
import moment from "moment/moment";
moment.locale("id");

let column = [];

export default {
  name: "UploadStudentData",
  components: { UploadExcel },
  filters: {
    dateFormat: value => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY");
    }
  },
  data() {
    return {
      moduleFileName: "REGISTRATION",
      filePhoto: null,
      dataError: [],
      imageFile: [],
      imageFileTemp: [],
      dataExcel: [],
      errorData: false,
      loading: false,
      clearData: false,
      loadingUpload: 0,
      loadingUploadSuccess: false,
      msgUpload: "",
      msgUploadStatus: false,
      tempStatus: [],
      count: 0,
      errorResponse: false,
      errorResponseList: [],
      errorMaxData: false,
      viewImageModal: false
    };
  },
  watch: {
    loadingProgress(newVal) {
      if (newVal == 100) {
        setTimeout(() => {
          this.msgUploadStatus = this.tempStatus.includes(false);
          this.loadingUploadSuccess = false;
          this.errorResponse = this.errorResponseList.length > 0;
          this.$store.commit("CALL_SNACKBAR", {
            msg: this.$i18n.t("master_data.student.response.done_upload_excel"),
            color: "success"
          });
          if (this.msgUploadStatus) {
            this.dataExcel.map(r => {
              if (!r.statusUpload) this.removeFile(r.siswa.data_diri.foto);
            });
          }
          this.handleClearExcel();
          this.dataExcel = [];
          this.imageFile = [];
          this.imageFileTemp = [];
          this.filePhoto = null;
        }, 750);
      }
    }
  },
  computed: {
    loadingProgress() {
      return this.dataExcel.length > 0
        ? Math.round((this.loadingUpload / this.dataExcel.length) * 100)
        : 0;
    }
  },
  methods: {
    goBack() {
      const path =
        "/master/" +
        this.$route.query.type +
        `?tab=${this.$route.query.type == "student" ? 2 : 0}`;
      this.$router.push(path);
    },
    removeFile(path) {
      const result = sliceAwsPath(path);
      deleteFile(result, this.moduleFileName).then(res => {
        if (res.data.code) {
          // remove path from tempImages
          const index = this.tempImages.indexOf(path);
          if (index > -1) {
            this.tempImages.splice(index, 1);
          }
        }
      });
    },
    handleClearExcel() {
      this.dataError = [];
      this.errorData = false;
      this.loadingUpload = 0;
      this.clearData = true;
      setTimeout(() => {
        this.clearData = false;
      }, 10);
    },
    async handleUploadFoto(file) {
      await file.map((r, idx) => {
        this.imageFile.push(r);
        this.imageFileTemp.push({ idx: idx, name: r.name });
      });

      setTimeout(() => {
        this.filePhoto = [];
      }, 50);
    },
    handleDeleteFile(idx) {
      if (idx) {
        this.imageFile.splice(idx, 1);
        this.imageFileTemp.splice(idx, 1);
      } else {
        this.imageFile = [];
        this.imageFileTemp = [];
      }
    },
    uploadExcelHandler(data) {
      this.loadingExport = true;
      this.failImport = false;
      this.setDataExcel(data);
    },
    async saveData(callback, { data, idx }) {
      const res = await insert_ppdb_siswa(data);
      callback({ ...res, index: idx });
    },
    processResponse(res) {
      this.msgUpload = res.data.message;
      this.count++;
      this.loadingUpload = this.count;
      if (res.data.code) {
        this.dataExcel[res.index].statusUpload = 1;
        this.tempStatus.push(true);
        setTimeout(() => {
          this.$router
            .push({
              path: "student",
              query: { tab: "2", radio: "2" }
            })
            .catch(err => err);
        }, 1000);
      } else {
        this.dataExcel[res.index].statusUpload = 0;
        this.tempStatus.push(false);
        this.errorResponseList.push({
          row: res.index + 2,
          name: res.data.data.siswa.data_diri.nama_lengkap,
          pob: res.data.data.siswa.data_diri.tempat_lahir,
          dob: res.data.data.siswa.data_diri.tanggal_lahir,
          message: res.data.message
        });
      }
    },
    submitExcel() {
      this.count = 0;
      this.loadingUploadSuccess = true;
      this.tempStatus = [];
      this.errorResponseList = [];
      this.msgUpload = "";
      if (this.dataExcel.length > 0) {
        for (let i = 0; i < this.dataExcel.length; i++) {
          const data = this.dataExcel[i];
          if (this.$route.query.type == "student") {
            let bodyImage = new FormData();
            bodyImage.set("path", "master_data");
            bodyImage.set("module", this.moduleFileName);
            const getImage = this.imageFile.filter(
              r =>
                r.name.toLowerCase() == data.siswa.data_diri.foto.toLowerCase()
            );

            if (getImage.length > 0) {
              bodyImage.append("file", getImage[0]);
              uploadFile(bodyImage).then(async imgRes => {
                if (imgRes.data.code) {
                  data.siswa.data_diri.foto = imgRes.data.data.path;
                  await this.saveData(this.processResponse, {
                    data: data,
                    idx: i
                  });
                } else {
                  this.handleClearExcel();
                  this.$store.commit("CALL_SNACKBAR", {
                    msg: imgRes.data.message,
                    color: "error"
                  });
                }
              });
            } else {
              this.saveData(this.processResponse, { data: data, idx: i });
            }
          }
        }
      }
    },
    setJson(key, data) {
      if (key) return key[data];
      else return "";
    },
    setValidation(key, idx, sheet) {
      this.dataError = [];
      if (this.$route.query.type == "student") {
        const row = idx + 2;
        switch (sheet) {
          case "student":
            if (key) {
              if (key.nama_lengkap == "")
                column.push({ row: row, column: "Nama Lengkap" });
              if (key.tempat_lahir == "")
                column.push({ row: row, column: "Tempat Lahir" });
              if (key.tanggal_lahir == "")
                column.push({ row: row, column: "Tanggal Lahir" });
              if (key.jenis_kelamin == "")
                column.push({ row: row, column: "Jenis Kelamin" });
              if (key.agama == "") column.push({ row: row, column: "Agama" });
              if (key.alamat == "") column.push({ row: row, column: "Alamat" });
            } else {
              column.push({
                row: row,
                column:
                  "Nama Lengkap, " +
                  "Tempat Lahir, " +
                  "Tanggal Lahir, " +
                  "Jenis Kelamin, " +
                  "Agama, " +
                  "Alamat"
              });
            }
            break;
          case "guardian":
            if (key) {
              if (key.nama == "")
                column.push({ row: row, column: "Nama Wali" });
              if (key.telepon == "")
                column.push({ row: row, column: "Telepon Wali" });
            } else {
              column.push({
                row: row,
                column: "Nama Wali, " + "Telepon Wali"
              });
            }
            break;
          default:
            break;
        }
      }

      setTimeout(() => {
        if (column.length > 0) {
          this.dataError = column;
          this.errorData = true;
        }
      }, 200);
    },
    setDataExcel(data) {
      this.dataExcel = [];
      column = [];
      this.errorMaxData = false;
      this.errorData = false;

      if (data[0].results.length >= 1001) {
        this.errorMaxData = true;
      } else {
        if (this.$route.query.type == "student") {
          const dataSiswa = data[0].results;
          const dk = data[1] ? data[1].results : []; // Data Keluarga
          const da = data[2] ? data[2].results : []; // Data Ayah
          const di = data[3] ? data[3].results : []; // Data Ibu
          const dw = data[4] ? data[4].results : []; // Data Wali

          dataSiswa.map((s, i) => {
            this.setValidation(s, i, "student");
            this.setValidation(dw[i], i, "guardian");

            let mapingDataExcel = {
              id_sekolah: window.id_school,
              auto_accept: "TRUE",
              is_excel: true,
              siswa: {
                completed: true,
                open_panel: [],
                data_diri: {
                  foto: s.foto,
                  nama_lengkap: s.nama_lengkap,
                  nis: s.nis,
                  nama_panggilan: s.nama_panggilan,
                  tempat_lahir: s.tempat_lahir,
                  tanggal_lahir: s.tanggal_lahir,
                  jenis_kelamin: s.jenis_kelamin,
                  agama: s.agama,
                  kewarganegaraan: s.kewarganegaraan,
                  bahasa: s.bahasa,
                  nik: s.nik,
                  no_kk: s.no_kk,
                  no_hp: s.no_hp,
                  email: s.email,
                  informasi_lainnya: s.informasi_lainnya
                },
                alamat_siswa: {
                  latlong: "",
                  alamat: s.alamat,
                  provinsi: s.provinsi,
                  kota: s.kota,
                  kecamatan: s.kecamatan,
                  kelurahan: s.kelurahan,
                  rt: s.rt,
                  rw: s.rw,
                  kode_pos: s.kodepos,
                  telepon_rumah: s.telepon_rumah,
                  anak_ke: s.anak_ke,
                  jumlah_saudara: s.jumlah_saudara,
                  tinggal_bersama: s.tinggal_bersama
                },
                data_keluarga: [
                  {
                    nama: this.setJson(dk[i], "anak_1"),
                    semester: this.setJson(
                      dk[i],
                      "sekolah_atau_bekerja_di_anak_1"
                    ),
                    sekolah: this.setJson(
                      dk[i],
                      "nama_sekolah_atau_perusahaan_anak_1"
                    )
                  },
                  {
                    nama: this.setJson(dk[i], "anak_2"),
                    semester: this.setJson(
                      dk[i],
                      "sekolah_atau_bekerja_di_anak_2"
                    ),
                    sekolah: this.setJson(
                      dk[i],
                      "nama_sekolah_atau_perusahaan_anak_2"
                    )
                  },
                  {
                    nama: this.setJson(dk[i], "anak_3"),
                    semester: this.setJson(
                      dk[i],
                      "sekolah_atau_bekerja_di_anak_3"
                    ),
                    sekolah: this.setJson(
                      dk[i],
                      "nama_sekolah_atau_perusahaan_anak_3"
                    )
                  },
                  {
                    nama: this.setJson(dk[i], "anak_4"),
                    semester: this.setJson(
                      dk[i],
                      "sekolah_atau_bekerja_di_anak_4"
                    ),
                    sekolah: this.setJson(
                      dk[i],
                      "nama_sekolah_atau_perusahaan_anak_4"
                    )
                  },
                  {
                    nama: this.setJson(dk[i], "anak_5"),
                    semester: this.setJson(
                      dk[i],
                      "sekolah_atau_bekerja_di_anak_5"
                    ),
                    sekolah: this.setJson(
                      dk[i],
                      "nama_sekolah_atau_perusahaan_anak_5"
                    )
                  }
                ],
                data_kesehatan: {
                  berat: s.berat,
                  tinggi: s.tinggi,
                  golongan_darah: s.golongan_darah,
                  riwayat: s.riwayat,
                  jarak: s.jarak,
                  catatan: s.catatan
                },
                pendidikan_sebelumnya: {
                  asal: s.asal,
                  no_pokok_sekolah: s.no_pokok_sekolah,
                  alamat_sekolah: s.alamat_sekolah,
                  no_induk_sekolah: s.no_induk_sekolah,
                  nisn: s.nisn,
                  tahun_lulus: s.tahun_lulus,
                  no_ijazah: s.no_ijazah
                }
              },
              orang_tua: {
                completed: true,
                open_panel: [],
                data_ayah: {
                  nama: this.setJson(da[i], "nama"),
                  tempat_lahir: this.setJson(da[i], "tempat_lahir"),
                  kewarganegaraan: this.setJson(da[i], "kewarganegaraan"),
                  pekerjaan: this.setJson(da[i], "pekerjaan"),
                  telepon: this.setJson(da[i], "telepon"),
                  hubungan_dengan_anak: this.setJson(
                    da[i],
                    "hubungan_dengan_anak"
                  ),
                  tanggal_lahir: this.setJson(da[i], "tanggal_lahir"),
                  pendidikan_terakhir: this.setJson(
                    da[i],
                    "pendidikan_terakhir"
                  ),
                  penghasilan_perbulan: this.setJson(
                    da[i],
                    "penghasilan_perbulan"
                  ),
                  email: this.setJson(da[i], "email"),
                  agama: this.setJson(da[i], "agama"),
                  informasi_lainnya: this.setJson(da[i], "informasi_lainnya")
                },
                alamat_ayah: {
                  latlong: "",
                  alamat: this.setJson(da[i], "alamat"),
                  provinsi: this.setJson(da[i], "provinsi"),
                  kota: this.setJson(da[i], "kota"),
                  kecamatan: this.setJson(da[i], "kecamatan"),
                  kelurahan: this.setJson(da[i], "kelurahan"),
                  rt: this.setJson(da[i], "rt"),
                  rw: this.setJson(da[i], "rw"),
                  kode_pos: this.setJson(da[i], "kode_pos"),
                  telepon_rumah: this.setJson(da[i], "telepon_rumah")
                },
                data_ibu: {
                  nama: this.setJson(di[i], "nama"),
                  tempat_lahir: this.setJson(di[i], "tempat_lahir"),
                  kewarganegaraan: this.setJson(di[i], "kewarganegaraan"),
                  pekerjaan: this.setJson(di[i], "pekerjaan"),
                  telepon: this.setJson(di[i], "telepon"),
                  hubungan_dengan_anak: this.setJson(
                    di[i],
                    "hubungan_dengan_anak"
                  ),
                  tanggal_lahir: this.setJson(di[i], "tanggal_lahir"),
                  pendidikan_terakhir: this.setJson(
                    di[i],
                    "pendidikan_terakhir"
                  ),
                  penghasilan_perbulan: this.setJson(
                    di[i],
                    "penghasilan_perbulan"
                  ),
                  email: this.setJson(di[i], "email"),
                  agama: this.setJson(di[i], "agama"),
                  informasi_lainnya: this.setJson(di[i], "informasi_lainnya")
                },
                alamat_ibu: {
                  latlong: "",
                  alamat: this.setJson(di[i], "alamat"),
                  provinsi: this.setJson(di[i], "provinsi"),
                  kota: this.setJson(di[i], "kota"),
                  kecamatan: this.setJson(di[i], "kecamatan"),
                  kelurahan: this.setJson(di[i], "kelurahan"),
                  rt: this.setJson(di[i], "rt"),
                  rw: this.setJson(di[i], "rw"),
                  kode_pos: this.setJson(di[i], "kode_pos"),
                  telepon_rumah: this.setJson(di[i], "telepon_rumah")
                }
              },
              wali: {
                isOrtu:
                  dw && dw.length > 0
                    ? dw[i].orang_tua_sebagai_wali
                      ? true
                      : false
                    : false,
                isFatherOrMother: this.setJson(dw[i], "orang_tua_sebagai_wali"),
                completed: true,
                open_panel: [],
                data_wali: {
                  nama: this.setJson(dw[i], "nama"),
                  tempat_lahir: this.setJson(dw[i], "tempat_lahir"),
                  kewarganegaraan: this.setJson(dw[i], "kewarganegaraan"),
                  pekerjaan: this.setJson(dw[i], "pekerjaan"),
                  telepon: this.setJson(dw[i], "telepon"),
                  hubungan_dengan_anak: this.setJson(
                    dw[i],
                    "hubungan_dengan_anak"
                  ),
                  tanggal_lahir: this.setJson(dw[i], "tanggal_lahir"),
                  pendidikan_terakhir: this.setJson(
                    dw[i],
                    "pendidikan_terakhir"
                  ),
                  penghasilan_perbulan: this.setJson(
                    dw[i],
                    "penghasilan_perbulan"
                  ),
                  email: this.setJson(dw[i], "email"),
                  agama: this.setJson(dw[i], "agama"),
                  informasi_lainnya: this.setJson(dw[i], "informasi_lainnya")
                },
                alamat_wali: {
                  latlong: "",
                  alamat: this.setJson(dw[i], "alamat"),
                  provinsi: this.setJson(dw[i], "provinsi"),
                  kota: this.setJson(dw[i], "kota"),
                  kecamatan: this.setJson(dw[i], "kecamatan"),
                  kelurahan: this.setJson(dw[i], "kelurahan"),
                  rt: this.setJson(dw[i], "rt"),
                  rw: this.setJson(dw[i], "rw"),
                  kode_pos: this.setJson(dw[i], "kode_pos"),
                  telepon_rumah: this.setJson(dw[i], "telepon_rumah")
                }
              },
              pernyataan: {
                isAgree: true
              }
            };

            // set data wali from parent data
            if (dw[i]) {
              switch (dw[i].isFatherOrMother) {
                case "ayah":
                  mapingDataExcel.wali.data_wali =
                    mapingDataExcel.orang_tua.data_ayah;
                  mapingDataExcel.wali.alamat_wali =
                    mapingDataExcel.orang_tua.alamat_ayah;
                  break;
                case "ibu":
                  mapingDataExcel.wali.data_wali =
                    mapingDataExcel.orang_tua.data_ibu;
                  mapingDataExcel.wali.alamat_wali =
                    mapingDataExcel.orang_tua.alamat_ibu;
                  break;
                default:
                  break;
              }
            }

            this.dataExcel.push(mapingDataExcel);
          });
        }
      }
    }
  }
};
</script>
