<template>
  <v-file-input
    v-model="fileUpload"
    :label="label"
    accept=".xlsx, .xls"
    prepend-icon="mdi-file-excel-box"
    outlined
    hide-details
    dense
    @click:clear="handleClear"
    @change="handleClick"
  ></v-file-input>
</template>

<script>
import XLSX from "xlsx";

export default {
  props: {
    beforeUpload: Function, // eslint-disable-line
    onSuccess: Function, // eslint-disable-line
    property: Object,
    label: String,
    multipleSheet: Boolean,
    clearData: Boolean
  },
  data() {
    return {
      onClick: this.handleUpload,
      loading: false,
      fileUpload: null,
      excelData: {
        header: null,
        results: null
      }
    };
  },
  watch: {
    clearData() {
      this.fileUpload = null;
    }
  },
  methods: {
    generateData({ header, results }) {
      this.excelData.header = header;
      this.excelData.results = results;
      this.onSuccess && this.onSuccess(this.excelData);
    },
    handleClear() {
      this.$emit("handleClear");
    },
    handleClick(e) {
      const rawFile = e;
      if (!rawFile) return;
      this.upload(rawFile);
    },
    upload(rawFile) {
      if (!this.beforeUpload) {
        this.readerData(rawFile);
        return;
      }
      const before = this.beforeUpload(rawFile);
      if (before) {
        this.readerData(rawFile);
      }
    },
    readerData(rawFile) {
      this.loading = true;
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = e => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          let firstSheetName = null;
          let worksheet = null;
          let headers = null;
          let results = null;
          const multiSheet = [];

          if (this.multipleSheet) {
            workbook.SheetNames.map((sheetName, i) => {
              worksheet = workbook.Sheets[sheetName];
              headers = this.getHeaderRow(worksheet);
              results = XLSX.utils.sheet_to_json(worksheet, {
                header: headers,
                range: 1,
                defval: ""
              });
              multiSheet.push({ headers, results });

              if (i === workbook.SheetNames.length - 1) {
                this.onSuccess(multiSheet);
                this.loading = false;
                resolve();
              }
            });
          } else {
            firstSheetName = workbook.SheetNames[0];
            worksheet = workbook.Sheets[firstSheetName];
            headers = this.getHeaderRow(worksheet);
            results = XLSX.utils.sheet_to_json(worksheet, {
              header: headers,
              range: 1,
              defval: ""
            });
            this.generateData({ headers, results });
            this.loading = false;

            resolve();
          }
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        let hdr = "UNKNOWN " + C;
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(this.format_column_name(hdr));
      }
      return headers;
    },
    format_column_name(name) {
      return name.toLowerCase().replace(/\s/g, "_");
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    }
  }
};
</script>
